/*
    This is the JS file that handles personalized behaviour for the site. Some of the functionalities handled are button functionality, form submission, and page switching.
*/

//GLOBALS
var ENVPRODUCTION = false;
var data = {
  service_id: "service_al7mqjq",
  user_id: "user_Uh91eT1BgmFXNFmaw64z8",
}; // Another key (template_params) is added individually

//--------------------------------------------- Start of modules -------------------------------

/**
 * Cohesive method to display a different page after an action
 * List of pages handled: [index, login, ]
 * @param {*} uriPage
 */
function switchPage(uriPage) {
  if (ENVPRODUCTION) {
    var splitURL = uriPage.split(".");
    console.log(splitURL);
    window.location.href = splitURL[0];
  } else {
    window.location.href = uriPage;
  }

  // if(uriPage === "login.html"){
  //     console.log("login")

  //     window.location.href=uriPage;
  // }
  // else if(uriPage === "demo.html"){

  // }
  // else if(uriPage === "learn.html"){
  //     window.location.href=uriPage;

  // }
  // else if(uriPage === "error.html"){
  //     window.location.href=uriPage;

  // }
  // else if(uriPage === "blog.html"){
  //     window.location.href=uriPage;

  // }
}
//============== Modules concerning contact-form ==========

function createJSONMessage(fName, lName, email, message, company) {
  var companyL = company.length;
  if (companyL != 0) {
    return {
      firstName: fName,
      lastName: lName,
      email: email,
      message: message,
      company: company,
    };
  } else {
    return {
      firstName: fName,
      lastName: lName,
      email: email,
      message: message,
      company: "NA",
    };
  }
}

function cleanFormInputs() {
  $("#firstNameInput").val("");
  $("#lastNameInput").val("");
  $("#emailInput").val("");
  $("#messageInput").val("");
  $("#companyInput").val("");
}
//----------------------------  JQuery --------------------------------------

var count = 0;
var condition = true;
var uvps = ["Maximize Profits", "Track Portions"];
$(function () {
  // shorthand document.ready function

  //First interval function that changes uvps
  setInterval(function () {
    count++;
    $("#uvpsInVerte").fadeOut(800, function () {
      $(this)
        .text(uvps[count % uvps.length])
        .fadeIn(800);
    });
  }, 6000);
  //Second interval function that changes explanation
  setInterval(function () {
    if (condition) {
      condition = false;
      $("#explanationUVP").fadeOut(800, function () {
        $(this)
          .text(
            "Our solution tracks food portions in real-time and provides feedback to kitchen teams."
          )
          .fadeIn(800);
      });
    } else {
      condition = true;
      $("#explanationUVP").fadeOut(800, function () {
        $(this)
          .text(
            "We help you manage food and labor costs better by enhancing consistency through automated pre-portioning."
          )
          .fadeIn(800);
      });
    }
  }, 6000);
  //Newsletter form
  $("#newsletterForm").submit(function (e) {
    e.preventDefault(); // prevent default behaviour
    // console.log($("#emailNewsletter").val());

    $("#emailNewsletterButton").attr("disabled", true);

    //Send email to our email
    data["template_params"] = { emailUser: $("#emailNewsletter").val() };
    data["template_id"] = "template_w8er11b";

    $.ajax("https://api.emailjs.com/api/v1.0/email/send", {
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    })
      .done(function () {
        // alert('Your mail is sent!');
        $("#emailNewsletterButton").html("Subscribed");
        // setTimeout(cleanFormInputs, 2500);
        console.log("Success it executed like expected");
      })
      .fail(function (error) {
        alert("Oops... " + JSON.stringify(error));
        // $("#submitButton").val("Send message")
        // $("#submitButton").attr("disabled", true);
      });
  });
  // Contact form submission on root
  $("#contactForm").submit(function (e) {
    e.preventDefault(); // prevent default behaviour

    //Inform user on status of request & prevent further clicks
    $("#submitButton").val("Sending message");
    $("#submitButton").attr("disabled", true);

    // Get all the forms input elements and their values & create message in one step
    var messageSend = createJSONMessage(
      $("#firstNameInput").val(),
      $("#lastNameInput").val(),
      $("#emailInput").val(),
      $("#messageInput").val(),
      $("#companyInput").val()
    );

    //In the future add accessToken & api verification
    data["template_params"] = messageSend;
    data["template_id"] = "template_xtvph67";

    $.ajax("https://api.emailjs.com/api/v1.0/email/send", {
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    })
      .done(function () {
        // alert('Your mail is sent!');
        $("#submitButton").val("Successfuly sent!");
        setTimeout(cleanFormInputs, 2500);
        // console.log("Success it executed like expected")
      })
      .fail(function (error) {
        alert("Oops... " + JSON.stringify(error));
        $("#submitButton").val("Send message");
        $("#submitButton").attr("disabled", true);
      });
  });
  // Sign Up Demo on demo
  $("#signUpDemoForm").submit(function (e) {
    e.preventDefault(); // prevent default behaviour
    // console.log()
    // console.log()
    // console.log()
    // console.log()

    $("#demoSubmitButton").html("Submitting");
    var checkBoxVals = [];
    $(":checkbox:checked").each(function (i) {
      checkBoxVals[i] = $(this).val();
    });
    // console.log(checkBoxVals);

    // Send inputs to API
    data["template_params"] = {
      name: $("#signUpDemoNameInput").val(),
      emailUser: $("#signUpDemoEmailInput").val(),
      company: $("#signUpDemoCompanyInput").val(),
      jobTitle: $("#signUpDemoJobTitleInput").val(),
      checkBoxes: checkBoxVals,
    };

    data["template_id"] = "template_w8er11b";
    $.ajax("https://api.emailjs.com/api/v1.0/email/send", {
      type: "POST",
      data: JSON.stringify(data),
      contentType: "application/json",
    })
      .done(function () {
        // alert('Your mail is sent!');
        // $("#submitButton").val("Successfuly sent!")
        // setTimeout(cleanFormInputs, 2500);
        console.log("Success it executed like expected");
        // Change to new page
        switchPage("thankYou");
      })
      .fail(function (error) {
        alert("Oops... " + JSON.stringify(error));
        // $("#submitButton").val("Send message")
        // $("#submitButton").attr("disabled", true);
      });
  });
  // Login form on login
  $("#logInForm").submit(function (e) {
    e.preventDefault(); // prevent default behaviour
    console.log($("#userNameLogInInput").val());
    console.log($("#passwordLogInInput").val());
    // Validate login
  });
  // Reset password form on login
  $("#resetPasswordForm").submit(function (e) {
    e.preventDefault(); // prevent default behaviour

    //Inform user on status of request & prevent further clicks
    $("#resetPasswordButton").html("Reset Form Sent");
    $("#resetPasswordButton").attr("disabled", true);

    // Call API to do the job, at the moment, only print the input
    console.log($("#emailResetPasswordForm").val());
  });
});
